import { useEffect } from 'react';
import { useDispatch } from 'react-redux'; // Sesuaikan path dengan lokasi action-mu
import { refreshPermissions } from '../store/Auth/action';

const REFRESH_INTERVAL = 1000 * 60 * 10; // 10 menit

const useRefreshPermissions = (user) => {
  const dispatch = useDispatch();

  useEffect(() => {
    let intervalId;

    if (user) {
      intervalId = setInterval(() => {
        console.log('refreshing permissions...');
        dispatch(refreshPermissions());
      }, REFRESH_INTERVAL);
    }

    // Cleanup interval saat user berubah atau component unmount
    return () => {
      if (intervalId) clearInterval(intervalId);
    };
  }, [user, dispatch]);
};

export default useRefreshPermissions;
