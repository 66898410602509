export const UPDATE_QTY = 'UPDATE_QTY';
export const ADD_PRODUCT = 'ADD_PRODUCT';
export const RESET_PRODUCT = 'RESET_PRODUCT';
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT';
export const DELETE_PRODUCT = 'DELETE_PRODUCT';
export const UPDATE_SUBTOTAL = 'UPDATE_SUBTOTAL';

export const UPDATE_ADDONS_PRICE = 'UPDATE_ADDONS_PRICE';
export const UPDATE_ADDONS = 'UPDATE_ADDONS';
export const REMOVE_ADDONS = 'REMOVE_ADDONS';

export const UPDATE_TOTAL_WEIGHT = 'UPDATE_TOTAL_WEIGHT';
export const UPDATE_PRICE_ADJUSTMENT = 'UPDATE_PRICE_ADJUSTMENT';
export const UPDATE_PRICE_DESIGN = 'UPDATE_PRICE_DESIGN';

export const UPDATE_NOTE_PRODUCT = 'UPDATE_NOTE_PRODUCT';
export const UPDATE_PRODUCT_KEY = 'UPDATE_PRODUCT_KEY';

export const ADDONS_IMAGE = 'ADDONS_IMAGE';
export const REMOVE_ADDONS_IMAGE = 'REMOVE_ADDONS_IMAGE';

// export const USER_LOADING = "USER_LOADING";
// export const USER_LOADED = "USER_LOADED";

// export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
// export const LOGIN_FAIL = "LOGIN_FAIL";
// export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
// export const AUTH_ERROR = "AUTH_ERROR";
// export const GET_ERRORS = "GET_ERRORS";
// export const CLEAR_ERRORS = "CLEAR_ERRORS";
