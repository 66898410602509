import { request } from '../config';

function ScoreboardServices() {
  const getSummary = async () => {
    const response = await request.get('/scoreboards/summary');
    return response;
  };

  const getSummaryOmzet = async () => {
    const response = await request.get('/scoreboards/omzet');
    return response;
  };

  const getSummaryOmzetPerUnit = async (unit) => {
    const response = await request.get('/scoreboards/omzet/' + unit);
    return response;
  };

  const getDataDetail = async () => {
    const response = await request.get('/scoreboards/detail');
    return response;
  };

  const getDataRank = async () => {
    const response = await request.get('/scoreboards/rank');
    return response;
  };

  const getDataRankPerUnit = async (unit) => {
    const response = await request.get('/scoreboards/rank/' + unit);
    return response;
  };

  const getScoreboardTeam = async () => {
    const response = await request.get('/scoreboards/team');
    return response;
  };

  const getScoreboardTarget = async () => {
    const response = await request.get('/scoreboards/target');
    return response;
  };

  const getScoreboardTargetPerUnit = async (unit) => {
    const response = await request.get('/scoreboards/target/' + unit);
    return response;
  };

  const getScoreboardTiktokPerUnit = async (unit) => {
    const response = await request.get('/scoreboards/tiktok/' + unit);
    return response;
  };

  const getScoreboardChart = async () => {
    const response = await request.get('/scoreboards/chart');
    return response;
  };

  const getScoreboardDealmaker = async () => {
    const response = await request.get('/scoreboards/dealmaker');
    return response;
  };

  const getScoreboardCsGroup = async () => {
    const response = await request.get('/scoreboards/csgroup');
    return response;
  };

  const changePoint = async (id, subject, point, target) => {
    const response = await request.put(`/scoreboards/${subject}/${target}`, {
      id,
      [subject]: point,
    });

    return response;
  };

  const getDetailData = async (id, periodicity) => {
    const response = await request.get(`/scoreboards/${periodicity}/${id}`);
    return response;
  };

  const getDetailUserData = async (id, unit) => {
    const response = await request.get(`/scoreboards/${unit}/${id}`);
    return response;
  };

  const updateScoreboard = async (unit, data) => {
    const response = await request.put(`/scoreboards/${unit}`, data);
    return response;
  };

  const getScoreboardLeague = async (params) => {
    const response = await request.get(`/scoreboards/league`, { params });
    return response;
  };

  const getScoreboardLeaguePeriod = async () => {
    const response = await request.get(`/scoreboards/league/period-options`);
    return response;
  };

  const getScoreboardLeagueCandidates = async () => {
    const response = await request.get(`/scoreboards/league/candidates`);
    return response;
  };

  const updateScoreboardLeague = async (data) => {
    const response = await request.put(`/scoreboards/league/membership`, data);
    return response;
  };

  return {
    updateScoreboard,
    getDetailData,
    getDataRank,
    getDataRankPerUnit,
    getDetailUserData,
    getSummary,
    getSummaryOmzet,
    getSummaryOmzetPerUnit,
    getDataDetail,
    changePoint,
    getScoreboardTeam,
    getScoreboardTarget,
    getScoreboardTargetPerUnit,
    getScoreboardChart,
    getScoreboardDealmaker,
    getScoreboardCsGroup,
    getScoreboardLeague,
    getScoreboardLeaguePeriod,
    getScoreboardLeagueCandidates,
    updateScoreboardLeague,
    getScoreboardTiktokPerUnit,
  };
}

export default ScoreboardServices();
