import { useTheme } from '@amcharts/amcharts4/core';
import am4themes_animated from '@amcharts/amcharts4/themes/animated';
import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { formatRp } from '../../helpers/formatRp';
import { config } from '../Scoreboard/Config';

import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import dayjs from 'dayjs';

const Team = ({ unit, data }) => {
  useTheme(am4themes_animated);
  ChartJS.register(
    CategoryScale,
    LogarithmicScale,
    BarElement,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

  const [currentMonthOmzet, setCurrentMonthOmzet] = useState(0);

  const { backgroundColor, textColor } = config[unit];
  const { title, omzet, chart } = data;
  let defaultToday = { omzet: 0, date: '' };
  let defaultYesterday = { omzet: 0, date: '' };
  let defaultChart = {
    labels: [
      'Januari',
      'Februari',
      'Maret',
      'April',
      'Mei',
      'Juni',
      'Juli',
      'Agustus',
      'September',
      'Oktober',
      'November',
      'Desember',
    ],
    datasets: [
      {
        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
        diff: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      },
    ],
  };

  const today = omzet.today || defaultToday;
  const yesterday = omzet.yesterday || defaultYesterday;
  const chartData = chart.labels?.length > 0 ? chart : defaultChart || defaultChart;

  useEffect(() => {
    if (chartData?.datasets[0].data.length > 0) {
      const currentMonthIndex = dayjs().month(); // Ambil indeks bulan saat ini
      const omzet = chartData?.datasets?.[0]?.data?.[currentMonthIndex] ?? 0;
      setCurrentMonthOmzet(omzet);
    }
  }, [chartData]);

  return (
    <>
      <div
        className="relative rounded-lg cursor-pointer uppercase antialiased"
        style={{
          backgroundColor,
          color: textColor,
        }}
      >
        <table className="border-collapse w-full">
          <thead>
            <tr>
              <th className="p-3 text-lg" colSpan={3}>
                {title || 'TEAM NAME'}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-center font-semibold">
              <td className="border-y border-r border-white w-[175.17px]">
                <p className="text-base">YESTERDAY</p>
                <p className="text-xs pb-2">{yesterday.date}</p>

                <p className="text-base font-bold">{formatRp(yesterday.omzet)}</p>
              </td>
              <td className="border-y border-r border-white p-2 w-[175.17px]">
                <p className="text-base">TODAY</p>
                <p className="text-xs pb-2">{today.date}</p>

                <p className="text-xl font-bold">{formatRp(today.omzet)}</p>
              </td>
              <td className="border-y border-white p-2 w-[175.17px]">
                <p className="text-base">BULAN</p>
                <p className="text-xs pb-2">{dayjs().format('MMMM YYYY')}</p>

                <p className="text-base font-bold">{formatRp(currentMonthOmzet)}</p>
              </td>
            </tr>

            <tr className="text-center font-semibold">
              <td colSpan={3} className="border-y border-white py-2 px-8">
                <Line
                  data={chartData}
                  options={{
                    responsive: true,
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        callbacks: {
                          label: function (context) {
                            let label = context.dataset.label || '';

                            if (label) {
                              label += ': ';
                            }
                            if (context.parsed.y !== null) {
                              label += formatRp(context.parsed.y);
                            }
                            return label;
                          },
                        },
                      },
                    },
                    borderColor: '#fff',
                    scales: {
                      y: {
                        beginAtZero: true,
                        ticks: {
                          // Include a dollar sign in the ticks
                          callback: function (value, index, values) {
                            return formatRp(value);
                          },
                          color: '#fff',
                        },
                      },
                      x: {
                        ticks: {
                          color: '#fff',
                        },
                      },
                    },
                  }}
                  id="chart-tiktok-dark"
                  className="chart-canvas mt-3"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default Team;
