import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { FLOW_VERSION } from './constants';
import { refreshPermissions } from './store/Auth/action';

export default function ReceiveBroadcast() {
  const { user } = useSelector((state) => state.auth);
  const socket = useSelector((state) => state.socket.io);
  const dispatch = useDispatch();

  useEffect(() => {
    if (socket.connected && user) {
      console.log('ready:', user.name);

      const alertReload = async (data) => {
        const notif = new Audio(
          'https://coversuper.sgp1.cdn.digitaloceanspaces.com/audio/elegant-notification.mp3'
        );
        await notif.play();

        Swal.fire({
          icon: 'info',
          title: data.version ? `Versi ${data.version}` : 'Pesan Broadcast',
          text: data.message,
          showCancelButton: true,
          confirmButtonText: 'Reload Sekarang',
          cancelButtonText: `Ingatkan lagi dalam 1 menit`,
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            window.location.reload();
          } else {
            console.log('reload nanti');
            setTimeout(() => alertReload(data), 60000);
          }
        });
      };

      // event listener for new flow version
      socket.on('newFlowVersion', function (data) {
        // console.log(data);
        if (data.version !== FLOW_VERSION) {
          alertReload(data);
        }
      });

      // event listener for broadcast message
      socket.on('newBroadcastMessage', function (data) {
        Swal.fire({
          imageUrl: 'https://sweetalert2.github.io/images/nyan-cat.gif',
          title: 'Pesan Broadcast',
          text: data.message,
          confirmButtonText: 'OK',
          timer: 5000, // 3 detik
          backdrop: `
            rgba(0,0,123,0.4)
            url("https://sweetalert2.github.io/images/nyan-cat.gif")
            left top
            no-repeat
          `,
        });
      });

      if (user.id) {
        const key = `newMessageForUser:${user.role_id}`;
        socket.on(key, function (data) {
          Swal.fire({
            icon: 'info',
            title: `Pesan dari ${data.sender} untuk ${user?.name}`,
            text: data.message,
            confirmButtonText: 'OK',
          });
        });
      }

      if (user.role) {
        const key = `newMessageForRole:${user.role_id}`;
        socket.on(key, function (data) {
          Swal.fire({
            icon: 'info',
            title: `Pesan dari ${data?.sender} untuk anda sebagai ${user?.role?.name}`,
            text: data.message,
            confirmButtonText: 'OK',
          });
        });

        socket.on(`refreshPermissions:${user.role_id}`, function (data) {
          if (data.need_update_permissions) {
            Swal.fire({
              icon: 'info',
              title: `Perubahan Hak Akses`,
              text: 'Hak akses anda telah diperbarui, silahkan refresh halaman',
            }).then(() => {
              dispatch(refreshPermissions());
            });
          }
        });
      }

      return () => {
        socket.off('newFlowVersion');
        socket.off('newBroadcastMessage');

        if (user.id && user.role_id) {
          socket.off(`newMessageForUser:${user.id}`);
          socket.off(`newMessageForRole:${user.role_id}`);
        }
      };
    }
  }, [socket, user, dispatch]);

  return <div></div>;
}
