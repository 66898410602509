/* eslint-disable react-hooks/exhaustive-deps */
import { Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import Loading from '../../components/Loading';
import ScoreboardServices from '../../services/ScoreboardServices';
import Team from './Team';

const formatSummaryPerUnit = {
  title: '',
  omzet: {
    today: {
      omzet: 0,
      date: '',
    },
    yesterday: {
      omzet: 0,
      date: '',
    },
  },
  chart: {
    labels: [],
    datasets: [
      {
        data: [],
        diff: [],
      },
    ],
  },
};

const ScoreboardTiktok = () => {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    unit: {
      excellent: formatSummaryPerUnit,
      great: formatSummaryPerUnit,
      kaizen: formatSummaryPerUnit,
      carmat: formatSummaryPerUnit,
      seatcover: formatSummaryPerUnit,
      force: formatSummaryPerUnit,
      kopassus_1: formatSummaryPerUnit,
      kopassus_2: formatSummaryPerUnit,
      kopassus_3: formatSummaryPerUnit,
      champ: formatSummaryPerUnit,
      avengers: formatSummaryPerUnit,
    },
  });

  const unitOptions = [];
  if (data.unit) {
    if (Object.keys(data.unit).length > 0) {
      Object.keys(data.unit).forEach((key) => {
        unitOptions.push({
          value: key,
          label: data.unit[key].title,
        });
      });
    }
  }

  useEffect(() => {
    reloadData();

    let interval = setInterval(() => {
      reloadData();
    }, 1000 * 15 * 60); // 15 menit

    return function cleanup() {
      clearInterval(interval);
    };
  }, []);

  const reloadData = () => {
    getDataTarget();
  };

  async function getDataTarget() {
    try {
      for (const unit of unitOptions) {
        setLoading(true);
        const response = await ScoreboardServices.getScoreboardTiktokPerUnit(unit.value);
        if (response.status === 200) {
          setData((prevState) => ({
            unit: {
              ...prevState.unit,
              [unit.value]: response.data.data,
            },
          }));
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      {loading && <Loading />}

      <div className="bg-sky-100 h-full">
        <div className="h-15 grid md:grid-cols-3 justify-items-center place-items-center content-center">
          <div>
            <Tooltip title="Home">
              <i className="fa-solid fa-home text-xl m-3" onClick={() => navigate('/')} />
            </Tooltip>
          </div>

          <div className="text-3xl font-bold">SCOREBOARD TIKTOK</div>
        </div>
        <div className="p-2">
          <div className="grid md:grid-cols-3 gap-6">
            <Team unit="excellent" data={data.unit.excellent} />
            <Team unit="great" data={data.unit.great} />
            <Team unit="carmat" data={data.unit.carmat} />

            <Team unit="kopassus_1" data={data.unit.kopassus_1} />
            <Team unit="seatcover" data={data.unit.seatcover} />
            <Team unit="force" data={data.unit.force} />

            <Team unit="kaizen" data={data.unit.kaizen} />
            <Team unit="avengers" data={data.unit.avengers} />
            <Team unit="kopassus_3" data={data.unit.kopassus_3} />

            {/* <Team unit="kopassus_2" data={data.unit.kopassus_2} />
            <Team unit="champ" data={data.unit.champ} /> */}
          </div>
        </div>
      </div>
    </>
  );
};

export default ScoreboardTiktok;
